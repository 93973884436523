/* BentonSans */
@font-face {
  font-family: 'BentonSans';
  font-style: normal;
  font-weight: 400;
  src: local('BentonSans Regular'), local('BentonSans-Regular'),
    url('./BentonSans/benton-sans-regular.woff2') format('woff2'),
    url('./BentonSans/benton-sans-regular.woff') format('woff');
}

@font-face {
  font-family: 'BentonSans-Light';
  src: url('./BentonSans/benton-sans-light.ttf') format('truetype');
}

@font-face {
  font-family: 'BentonSans-Medium';
  src: url('./BentonSans/benton-sans-medium.ttf') format('truetype');
}

/* Open Sans */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('./OpenSans/open-sans-v15-latin-regular.woff2') format('woff2'),
    url('./OpenSans/open-sans-v15-latin-regular.woff') format('woff');
}

/* Quicksand */
@font-face {
  font-family: 'Quicksand';
  font-style: normal;
  font-weight: 400;
  src: local('Quicksand Regular'), local('Quicksand-Regular'),
    url('./Quicksand/quicksand-v7-latin-regular.woff2') format('woff2'),
    url('./Quicksand/quicksand-v7-latin-regular.woff') format('woff');
}
@font-face {
  font-family: 'Quicksand-Bold';
  src: url('./Quicksand/Quicksand-Bold.ttf') format('truetype');
}
